import { createRef, PureComponent, ReactNode, RefObject } from 'react';
import BsDropdown from 'react-bootstrap/Dropdown';
import { FaSortAmountDown, FaSortAmountDownAlt } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { Asserter } from '../common/Asserter';
import { Nullable } from '../common/Optional';
import { IRegistrationClient } from '../common/RegistrationClient';
import { ChoiceModel } from '../models/ChoiceModel';
import { FilteredCollection } from '../models/FilteredCollection';
import { FlagModel } from '../models/FlagModel';
import { IChoiceModel } from '../models/IChoiceModel';
import { ICompetitorModel } from '../models/ICompetitorModel';
import { IndicatorModel } from '../models/IndicatorModel';
import { IRegistrationModel } from '../models/IRegistrationModel';
import { IRegistrationsModel } from '../models/IRegistrationsModel';
import { ITextModel } from '../models/ITextModel';
import { reversed, SortedCollection } from '../models/SortedCollection';
import { TextModel } from '../models/TextModel';
import { Dropdown } from './Dropdown';
import { Indicator } from './Indicator';
import { Label } from './Label';
import { LineEditWithModel } from './LineEdit';
import { ModalOkCancel } from './Modal';
import { RadioButtonsWithModel } from './RadioButtons';
import { RegistrationsOverviewRow } from './RegistrationsOverviewRow';
import { RegistrationsStatusLine } from './RegistrationsStatusLine';
import { Row } from './Row';


type Props = {
	client: IRegistrationClient;
	registrations: IRegistrationsModel;
};


type State = {
	pendingDelete: Nullable<IRegistrationModel>;
};


class RegistrationsOverview extends PureComponent<Props, State>
{
	constructor(props: Props)
	{
		super(props);

		this.state = {
			pendingDelete: null
		};

		this._onChanged = this._onChanged.bind(this);
		this._onSave = this._onSave.bind(this);
		this._onDelete = this._onDelete.bind(this);
		this._onDeleteCancelled = this._onDeleteCancelled.bind(this);
		this._onReallyDelete = this._onReallyDelete.bind(this);
		this._onConfirm = this._onConfirm.bind(this);
		this._onSortingChanged = this._onSortingChanged.bind(this);
		this._onRefreshAll = this._onRefreshAll.bind(this);
		this._onRefreshSingle = this._onRefreshSingle.bind(this);
		this._onSwapVornameNachname = this._onSwapVornameNachname.bind(this);
		this._onNormalizeCase = this._onNormalizeCase.bind(this);
		this._onDuplicate = this._onDuplicate.bind(this);
		this._onCreateNew = this._onCreateNew.bind(this);
		this._onDownloadAllRegistrations = this._onDownloadAllRegistrations.bind(this);
		this._onDownloadAllRegisteredCompetitors = this._onDownloadAllRegisteredCompetitors.bind(this);

		this._filtered = new FilteredCollection<IRegistrationModel>(this.props.registrations.registrations, _filterMatches);
		this._sorted = new SortedCollection<IRegistrationModel>(this._filtered, _getSortFunction(this._sortChoices.selected));
		this._searchRef = createRef<LineEditWithModel>();
	}

	render(): ReactNode
	{
		const rows = this._sorted.items.map(r => (
			<RegistrationsOverviewRow
				key={r.id.text}
				registration={r}
				onSave={this._onSave}
				onDelete={this._onDelete}
				onConfirm={this._onConfirm}
				onRefresh={this._onRefreshSingle}
				onSwapVornameNachname={this._onSwapVornameNachname}
				onNormalizeCase={this._onNormalizeCase}
				onDuplicate={this._onDuplicate}
			/>
		));

		return (
			<>
				<div className="container-lg my-4">
					<Row className="mb-2">
						<Label label="Search:" className="col-auto align-self-center" />
						<div className="col align-self-center">
							<LineEditWithModel ref={this._searchRef} model={this._filtered.filter} type="search" />
						</div>
						<div className="col-auto align-self-center">
							<Dropdown label="Sortieren">
								<RadioButtonsWithModel model={this._sortChoices} valueToLabel={_sortChoiceToLabel} />
							</Dropdown>
						</div>
						<div className="col-auto align-self-center">
							<Dropdown>
								<BsDropdown.Item onClick={this._onRefreshAll}>Refresh</BsDropdown.Item>
								<BsDropdown.Item onClick={this._onCreateNew}>Neue Meldung</BsDropdown.Item>
								<BsDropdown.Item as={NavLink} to="/registrations/distribution">Verteilung</BsDropdown.Item>
								<BsDropdown.Item onClick={this._onDownloadAllRegistrations}>Alle Meldungen (Download)</BsDropdown.Item>
								<BsDropdown.Item onClick={this._onDownloadAllRegisteredCompetitors}>Alle Kämpfer (Download)</BsDropdown.Item>
							</Dropdown>
						</div>
					</Row>
					<Indicator model={this._indicatorModel} />
					<RegistrationsStatusLine registrations={this._filtered} />
					{rows}
				</div>
				<ConfirmDeleteModal registration={this.state.pendingDelete} onOk={this._onReallyDelete} onCancel={this._onDeleteCancelled} />
			</>
		);
	}

	componentDidMount(): void
	{
		this._sorted.onChanged.subscribe(this._onChanged);
		this._sortChoices.onChanged.subscribe(this._onSortingChanged);
		this._searchRef.current!.focus();
	}

	componentWillUnmount(): void
	{
		this._sorted.onChanged.unsubscribe(this._onChanged);
		this._sortChoices.onChanged.unsubscribe(this._onSortingChanged);
	}

	componentDidUpdate(prevProps: Props): void
	{
		this._filtered.setSource(this.props.registrations.registrations);
	}

	private _onChanged(): void
	{
		this.forceUpdate();
	}

	private _onSave(registration: IRegistrationModel): Promise<void>
	{
		Asserter.assert(registration.wasChanged.value === true, 'inconsistency');

		return registration.save();
	}

	/**
	 * Wird gerufen, wenn der User den Delete-Button der Row drückt.
	 * Daraufhin zeigen wir aber erst mal einen Bestätigungsdialog.
	 * Erst wenn der User dort bestätigt, wird die Meldung gelöscht.
	 */
	private _onDelete(registration: IRegistrationModel): void
	{
		// Wir müssen uns die Meldung merken. Der Dialog selbst kennt die nicht.
		// Der Callback für den Dialog ist ja auch schon gesetzt, dort können wir
		// die Meldung also auch nicht durch Binding hinterlegen.
		this.setState({ pendingDelete: registration });
	}

	private _onDeleteCancelled(): void
	{
		Asserter.assert(this.state.pendingDelete !== null, 'inconsistency');
		this.setState({ pendingDelete: null });
	}

	/**
	 * Wird vom Bestätigungsdialog gerufen, wenn der User das Löschen
	 * der Meldung bestätigt hat.
	 */
	private _onReallyDelete(): void
	{
		Asserter.assert(this.state.pendingDelete !== null, 'inconsistency');

		// Hmm, remove() ist async.
		this.props.registrations.remove(this.state.pendingDelete);
		this.setState({ pendingDelete: null });
	}

	/**
	 * Wir wollen hier die Bestätigung so durchführen, wie es der Meldende auch tut.
	 * Und wir wollen es auch in dem Fall, wo die Meldung bereits bestätigt wurde,
	 * sodass auch noch mal eine E-Mail rausgeschickt wird.
	 * Das forcieren der Bestätigung soll dem Meldenden jedoch nicht möglich sein.
	 */
	private _onConfirm(registration: IRegistrationModel): Promise<void>
	{
		return registration.forceConfirm();
	}

	private _onSortingChanged(model: IChoiceModel<string>): void
	{
		this._sorted.setCompareFn(_getSortFunction(model.selected));
	}

	private _onRefreshAll(): Promise<void>
	{
		return this._indicatorModel.run(
			() => this.props.registrations.refresh(),
			'Fehler beim Aktualisieren der Meldungen',
			'Alle Meldungen erfolgreich aktualisiert'
		);
	}

	private _onRefreshSingle(registration: IRegistrationModel): Promise<void>
	{
		return registration.refresh();
	}

	/**
	 * Tausche Vor- und Nachname des Meldenden und aller Competitors.
	 * Könnte auch direkt eine Methode von IRegistrationModel sein.
	 */
	private _onSwapVornameNachname(registration: IRegistrationModel): void
	{
		registration.onChanged.collectWhile(() => {
			TextModel.swapValues(registration.vorname, registration.nachname);
			registration.competitors.items.forEach(_swapVornameNachname);
		});
	}

	/**
	 * Normalisierung der Groß-/Kleinschreibung.
	 */
	private _onNormalizeCase(registration: IRegistrationModel): void
	{
		registration.onChanged.collectWhile(() => {
			TextModel.normalizeCase(registration.vorname);
			TextModel.normalizeCase(registration.nachname);
			registration.competitors.items.forEach(_normalizeCase);
		});
	}

	private _onDuplicate(registration: IRegistrationModel): void
	{
		this.props.registrations.registrations.add(registration.clone());
	}

	private _onCreateNew(): void
	{
		this.props.registrations.create();
	}

	private async _onDownloadAllRegistrations(): Promise<void>
	{
		const csvBlob = await this.props.client.allAsCsv();
		const csvFile = new File([csvBlob], 'Alle Meldungen.csv', {
			type: 'text/csv; charset=UTF-8'
		});
		const url = URL.createObjectURL(csvFile);
		window.open(url, '_self');
		URL.revokeObjectURL(url);
	}

	private async _onDownloadAllRegisteredCompetitors(): Promise<void>
	{
		const csvBlob = await this.props.client.allCompetitorsAsCsv();
		const csvFile = new File([csvBlob], 'Alle Kämpfer.csv', {
			type: 'text/csv; charset=UTF-8'
		});
		const url = URL.createObjectURL(csvFile);
		window.open(url, '_self');
		URL.revokeObjectURL(url);
	}

	private readonly _filtered: FilteredCollection<IRegistrationModel>;
	private readonly _sorted: SortedCollection<IRegistrationModel>;
	private readonly _sortChoices = new ChoiceModel(['Id aufsteigend', 'Id absteigend', 'Datum aufsteigend', 'Datum absteigend'], 'Datum absteigend');
	private readonly _indicatorModel = new IndicatorModel();
	private readonly _searchRef: RefObject<LineEditWithModel>;
}


//==============================================================================


type ModalProps = {
	registration: Nullable<IRegistrationModel>;
	onOk: () => void;
	onCancel: () => void;
};


class ConfirmDeleteModal extends PureComponent<ModalProps>
{
	render(): ReactNode
	{
		if (this.props.registration === null)
			return null;

		this._isShown.setValue(true);

		return (
			<ModalOkCancel
				isShown={this._isShown}
				title="Bestätigung"
				content={<p>Meldung {this.props.registration.id.text} wirklich löschen?</p>}
				onOk={this.props.onOk}
				onCancel={this.props.onCancel}
			/>
		);
	}

	private readonly _isShown = new FlagModel(false);
}


//==============================================================================


/**
 * Liefert für den RadioButton zu jeder Auswahlmöglichkeit ein Label.
 */
function _sortChoiceToLabel(choice: string): ReactNode
{
	const iconAsc = <FaSortAmountDownAlt size="0.9em" className="align-middle me-2" />;
	const iconDesc = <FaSortAmountDown size="0.9em" className="align-middle me-2" />;
	switch (choice)
	{
		case 'Id aufsteigend':
			return <>{iconAsc}Id</>;
		case 'Id absteigend':
			return <>{iconDesc}Id</>;
		case 'Datum aufsteigend':
			return <>{iconAsc}Datum</>;
		case 'Datum absteigend':
			return <>{iconDesc}Datum</>;
		default:
			Asserter.fail('invalid choice');
	}
}


function _filterMatches(registration: IRegistrationModel, filterText: string): boolean
{
	const filter = filterText.toLowerCase();

	if (filter.length === 0)
		return true;

	if (_matchesText(registration.id, filter))
		return true;
	if (_matchesText(registration.vorname, filter))
		return true;
	if (_matchesText(registration.nachname, filter))
		return true;
	if (_matchesText(registration.email, filter))
		return true;
	if (_matchesText(registration.verein, filter))
		return true;
	if (_matchesText(registration.eingegangen, filter))
		return true;

	if (registration.competitors.items.find(c => _competitorMatches(c, filter)) !== undefined)
		return true;

	return false;
}

function _competitorMatches(competitor: ICompetitorModel, filter: string): boolean
{
	if (_matchesText(competitor.vorname, filter))
		return true;
	if (_matchesText(competitor.nachname, filter))
		return true;

	return false;
}

function _matchesText(textModel: ITextModel, filter: string): boolean
{
	return textModel.text.toLowerCase().indexOf(filter) !== -1;
}


function _getSortFunction(selection: string): (lhs: IRegistrationModel, rhs: IRegistrationModel) => number
{
	switch (selection)
	{
		case 'Id aufsteigend':
			return _cmpRegistrationById;
		case 'Id absteigend':
			return reversed(_cmpRegistrationById);
		case 'Datum aufsteigend':
			return _cmpRegistrationByDate;
		case 'Datum absteigend':
			return reversed(_cmpRegistrationByDate);
		default:
			Asserter.fail('invalid sorting');
	}
}


function _cmpRegistrationById(lhs: IRegistrationModel, rhs: IRegistrationModel): number
{
	const leftId = lhs.id.text;
	const rightId = rhs.id.text;
	return leftId.localeCompare(rightId);
}

function _cmpRegistrationByDate(lhs: IRegistrationModel, rhs: IRegistrationModel): number
{
	const leftDate = lhs.eingegangen.text;
	const rightDate = rhs.eingegangen.text;
	const dateCmp = leftDate.localeCompare(rightDate);
	if (dateCmp !== 0)
		return dateCmp;

	return _cmpRegistrationById(lhs, rhs);
}

function _swapVornameNachname(competitor: ICompetitorModel): void
{
	TextModel.swapValues(competitor.vorname, competitor.nachname);
}

function _normalizeCase(competitor: ICompetitorModel): void
{
	TextModel.normalizeCase(competitor.vorname);
	TextModel.normalizeCase(competitor.nachname);
}


export { RegistrationsOverview };
