import { PureComponent, ReactNode } from 'react';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import { IBackend } from '../common/IBackend';
import { renderElementList } from '../common/utils';
import { Flag } from '../components/Flag';
import { HideableWithModel } from '../components/Hideable';
import { Newsticker } from '../components/Newsticker';
import { ConfigModel } from '../models/ConfigModel';


type Props = {
	config: ConfigModel;
	backend: IBackend;
};


class HomeRoute extends PureComponent<Props>
{
	render(): ReactNode
	{
		const flags = renderElementList(_nations.map(nation => (
			<span className="mx-1">
				<Flag nation={nation} />
			</span>
		)));

		return (
			<main>
				<section className="bg-container text-dark d-flex flex-column align-items-center my-5">
					<div className="title-1 mt-5 pt-3">Judo</div>
					<div className="title-2 mt-4">International Masters Bremen</div>
					<div className="title-2">20-22 March 2026</div>
					<div className="title-2">Men U18/U21</div>
					<div className="title-3 mt-5">
						Men U18: 20/21 March 2026
						<HideableWithModel isShown={this.props.config.tournamentDetailsEnabled}>
							<Link to="/invitation/u18" className="btn btn-sm btn-outline-dark ms-2 rounded-pill">Details</Link>
						</HideableWithModel>
					</div>
					<div className="title-3 mt-1">
						Men U21: 21/22 March 2026
						<HideableWithModel isShown={this.props.config.tournamentDetailsEnabled}>
							<Link to="/invitation/u21" className="btn btn-sm btn-outline-dark ms-2 rounded-pill">Details</Link>
						</HideableWithModel>
					</div>
					<div className="title-3 mt-1">
						Training Camp: 23-26 March 2026
						<HideableWithModel isShown={this.props.config.campDetailsEnabled}>
							<Link to="/invitation/camp" className="btn btn-sm btn-outline-dark ms-2 rounded-pill">Details</Link>
						</HideableWithModel>
					</div>
				</section>

				<section className="d-flex flex-column align-items-center my-5">
					<HideableWithModel isShown={this.props.config.registrationEnabled}>
						<Link to="/registration/hub" className="btn btn-lg btn-secondary fw-bold rounded-pill">Register Now</Link>
					</HideableWithModel>
				</section>

				<section className="d-flex flex-column align-items-center text-center px-2">
					<h5>
						{/* As of 16 March 2025 we have 794 registered Judoka from the following 26 countries: */}
						At the Masters Bremen 2025 we had 759 registered Judoka from the following 27 nations:
					</h5>
					<div>
						{flags}
					</div>
				</section>

				<HideableWithModel isShown={this.props.config.newstickerEnabled}>
					<section>
						<Container fluid="lg" className="my-4">
							<Newsticker newstickerClient={this.props.backend.newstickerEntries} />
						</Container>
					</section>
				</HideableWithModel>
			</main>
		);
	}
}


//==============================================================================


const _nations = [
	'ARM',
	'AUS',
	'AZE',
	'BEL',
	'BRA',
	'BUL',
	'CRO',
	'CZE',
	'DEN',
	'FIN',
	'FRA',
	'GBR',
	'GEO',
	'GRE',
	'IRL',
	'JPN',
	'LIE',
	'MDA',
	'NED',
	'POL',
	'POR',
	'ROU',
	'RUS',
	'SUI',
	'SWE',
	'UKR',
	'GER',
];


export { HomeRoute };
