import { IValidator } from '../common/IValidator';
import { ChoiceModel } from './ChoiceModel';
import { EventForwarder } from './EventForwarder';
import { IChoiceModel } from './IChoiceModel';
import { IEvent } from './IEvent';
import { IValidatedChoiceModel } from './IValidatedChoiceModel';


class ValidatedChoiceModel<TElement> implements IValidatedChoiceModel<TElement>
{
	/**
	 * Convenience-Factory-Funktion.
	 */
	// Diese static-Funktion muss irgendwie eine Template-Funktion sein;
	// sie kann nicht auf die Template-Parameter der Klasse zugreifen.
	static create<TElement>(choices: Readonly<TElement[]>, selected: TElement, validator: IValidator<TElement>): ValidatedChoiceModel<TElement>
	{
		const choiceModel = new ChoiceModel(choices, selected);
		return new ValidatedChoiceModel(choiceModel, validator);
	}

	constructor(choiceModel: IChoiceModel<TElement>, validator: IValidator<TElement>)
	{
		this._choiceModel = choiceModel;
		this._validator = validator;
		this._onChanged = new EventForwarder(this._choiceModel.onChanged, this);
	}

	get onChanged(): IEvent<this>
	{
		return this._onChanged.event;
	}

	get choices(): Readonly<TElement[]>
	{
		return this._choiceModel.choices;
	}

	setChoices(choices: Readonly<TElement[]>)
	{
		this._choiceModel.setChoices(choices);
	}

	get selected(): TElement
	{
		return this._choiceModel.selected;
	}

	setSelected(item: TElement)
	{
		this._choiceModel.setSelected(item);
	}

	isValid(): boolean
	{
		return this._validator.isValid(this.selected);
	}

	private readonly _choiceModel: IChoiceModel<TElement>;
	private readonly _validator: IValidator<TElement>;
	private readonly _onChanged: EventForwarder<typeof this._choiceModel, this>;
}


/**
 * Man kann aktuell keinen ordentlich funktionierenden Type-Alias machen.
 * Folgendes funktioniert daher nicht:
 * ```
 * type ValidatedChoiceModel = GenericValidatedChoiceModel<string>;
 * ```
 *
 * Die Alternative ist Ableitung.
 * @see https://github.com/microsoft/TypeScript/issues/2552
 */
// class ValidatedChoiceModel extends GenericValidatedChoiceModel<string>
// {}


export { ValidatedChoiceModel };
