import { IValidator } from '../common/IValidator';
import { EventForwarder } from './EventForwarder';
import { IEvent } from './IEvent';
import { IValidatedTextModel } from './IValidatedTextModel';
import { TextModel } from './TextModel';


class ValidatedTextModel implements IValidatedTextModel
{
	constructor(text: string, validator: IValidator<string>)
	{
		this._textModel = new TextModel(text);
		this._validator = validator;
		this._onChanged = new EventForwarder(this._textModel.onChanged, this);
	}

	get onChanged(): IEvent<this>
	{
		return this._onChanged.event;
	}

	isValid(): boolean
	{
		return this._validator.isValid(this._textModel.text);
	}

	get text(): string
	{
		return this._textModel.text;
	}

	setText(text: string): void
	{
		this._textModel.setText(text);
	}

	private readonly _textModel: TextModel;
	private readonly _validator: IValidator<string>;
	private readonly _onChanged: EventForwarder<TextModel, this>;
}


export { ValidatedTextModel };

