import { createPopper, Instance, Placement } from '@popperjs/core';
import { createRef, PureComponent, RefObject } from 'react';
import { Asserter } from '../common/Asserter';
import { Optional } from '../common/Optional';
import { ElementOrRenderProp, render } from '../common/RenderProp';
import { Hideable } from '../components/Hideable';


type Props = {
	content: ElementOrRenderProp;
	tooltip: ElementOrRenderProp;
	onTooltipToggled?: (isOn: boolean) => void;
	placement?: Placement
};


type State = {
	isTooltipShown: boolean;
};


/**
 * Rendert den Content in einem div-Element.
 * Das div-Element dient als Trigger für das Tooltip: Bei mouseover
 * wird das Tooltip gerendert.
 */
class WithTooltip extends PureComponent<Props, State>
{
	constructor(props: Props)
	{
		super(props);

		this.state = {
			isTooltipShown: false
		};

		this._showTooltip = this._showTooltip.bind(this);
		this._hideTooltip = this._hideTooltip.bind(this);

		this._trigger = createRef<HTMLDivElement>();
		this._tooltip = createRef<HTMLDivElement>();
	}

	render()
	{
		return (
			<>
				<div ref={this._trigger} onMouseEnter={this._showTooltip} onMouseLeave={this._hideTooltip}>
					{render(this.props.content)}
				</div>
				<Hideable isShown={this.state.isTooltipShown}>
					<div ref={this._tooltip} className="tooltip fw-bold bg-dark text-light rounded-1 px-2 py-1" role="tooltip">
						{render(this.props.tooltip)}
						<div className="tooltip-arrow" data-popper-arrow />
					</div>
				</Hideable>
			</>
		);
	}

	componentWillUnmount()
	{
		this._destroyPopper();
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: {})
	{
		if (this.state.isTooltipShown)
			this._createPopper();
		else
			this._destroyPopper();
	}

	private _showTooltip()
	{
		this.setState({ isTooltipShown: true });
		this.props.onTooltipToggled?.call(undefined, true);
	}

	private _hideTooltip()
	{
		this.setState({ isTooltipShown: false });
		this.props.onTooltipToggled?.call(undefined, false);
	}

	private _createPopper()
	{
		Asserter.assert(this._popper === undefined, 'inconsistency');
		Asserter.assert(this._trigger.current !== null, 'inconsistency');
		Asserter.assert(this._tooltip.current !== null, 'inconsistency');

		this._popper = createPopper(this._trigger.current!, this._tooltip.current!, {
			placement: this.props.placement ?? 'top',
			modifiers: [
				{
					name: 'offset',
					options: {
						offset: [0, 10],
					},
				},
			],
		});
	}

	private _destroyPopper()
	{
		this._popper?.destroy();
		this._popper = undefined;
	}

	private _trigger: RefObject<HTMLDivElement>;
	private _tooltip: RefObject<HTMLDivElement>;
	private _popper: Optional<Instance>;
}


export { WithTooltip };
