import { Asserter } from '../common/Asserter';
import { Event } from './Event';
import { IChoiceModel } from './IChoiceModel';
import { IEvent, moveSubscription, withoutSubscription } from './IEvent';


/**
 * Filterfunktion.
 */
type Filter<TElement> = (item: TElement) => boolean;


/**
 * Ein Decorator für ein IChoiceModel.
 * Es werden nur die Element des sourceModels durchgelassen, bei denen der Filter matcht.
 */
class FilteredChoiceModel<TElement> implements IChoiceModel<TElement>
{
	constructor(sourceModel: IChoiceModel<TElement>, filter: Filter<TElement>)
	{
		this._onSourceModelChanged = this._onSourceModelChanged.bind(this);

		this._sourceModel = sourceModel;
		this._filter = filter;

		this._forceFilter();

		this._sourceModel.onChanged.subscribe(this._onSourceModelChanged);
	}

	get onChanged(): IEvent<this>
	{
		return this._onChanged;
	}

	get choices(): Readonly<TElement[]>
	{
		return this._sourceModel.choices.filter(this._filter);
	}

	setChoices(choices: Readonly<TElement[]>): void
	{
		// Hmm, wie soll ich das implementieren?
		// Macht das überhaupt Sinn?
		Asserter.fail('not implemented yet');
	}

	get selected(): TElement
	{
		const selected = this._sourceModel.selected;
		Asserter.assert(this._filter(selected) === true, 'filter must match');
		return selected;
	}

	setSelected(item: TElement): void
	{
		Asserter.assert(this._filter(item) === true, 'filter must match')
		this._sourceModel.setSelected(item);
	}

	setFilter(filter: Filter<TElement>): void
	{
		this._filter = filter;
		this._forceFilter();
		this._notifyChange();
	}

	setModel(sourceModel: IChoiceModel<TElement>): void
	{
		moveSubscription(this._sourceModel.onChanged, sourceModel.onChanged, this._onSourceModelChanged);
		this._sourceModel = sourceModel;
		this._onSourceModelChanged();
	}

	private _onSourceModelChanged(): void
	{
		withoutSubscription(this._sourceModel.onChanged, this._onSourceModelChanged, () => {
			this._forceFilter();
		})
		this._notifyChange();
	}

	private _notifyChange(): void
	{
		this._onChanged.notify(this, undefined);
	}

	/**
	 * Stellt sicher, dass das der Filter beim aktuell selektierten Element des sourceModels matched.
	 * Wenn nicht, wird das erste Element der gefilterten Elemente selektiert.
	 * Der Filter muss also mindestens ein Element durchlassen.
	 */
	private _forceFilter(): void
	{
		const selected = this._sourceModel.selected;
		if (this._filter(selected) === true)
			return;

		const firstMatching = this._sourceModel.choices.find(this._filter);
		Asserter.assert(firstMatching !== undefined, 'need a choice');
		this._sourceModel.setSelected(firstMatching);
	}

	private _sourceModel: IChoiceModel<TElement>;
	private _filter: Filter<TElement>;
	private readonly _onChanged = new Event<this>();
}


export { FilteredChoiceModel };
