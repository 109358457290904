import { Asserter } from '../common/Asserter';
import { Nullable, Optional } from '../common/Optional';
import { stringToInt } from '../common/utils';
import { DummyValidator, MultiAgeGroupValidator, NameValidator, NotNullValidator } from '../common/Validators';
import { CampCompetitorData } from '../data/CampCompetitorData';
import { AgeGroupServerData, AgeGroupsServerData } from '../data/RegistrationFormData';
import { ComputedFlagModel } from './ComputedFlagModel';
import { Event } from './Event';
import { IAltersklasseChoiceModel, ICampCompetitorModel } from './ICampCompetitorModel';
import { ICompetitorModel } from './ICompetitorModel';
import { IEvent } from './IEvent';
import { IFlagModel } from './IFlagModel';
import { IValidatedTextModel } from './IValidatedTextModel';
import { ValidatedChoiceModel } from './ValidatedChoiceModel';
import { ValidatedTextModel } from './ValidatedTextModel';


class CampCompetitorModel implements ICampCompetitorModel
{
	/**
	 * Erzeugt ein CampCompetitorModel aus einem CompetitorModel.
	 * Das wird zum Vorausfüllen der Camp-Meldung verwendet.
	 */
	static create(ageGroups: AgeGroupsServerData, competitor: ICompetitorModel)
	{
		const campCompetitor = new this(ageGroups);

		campCompetitor.vorname.setText(competitor.vorname.text);
		campCompetitor.nachname.setText(competitor.nachname.text);
		campCompetitor.geburtsjahr.setText(competitor.geburtsjahr.text);
		campCompetitor.altersklasse.setSelected(competitor.altersklasse.selected);

		return campCompetitor;
	}

	/**
	 * Ich verwende hier jetzt direkt mal ServerData.
	 * Der Umweg über Data bringt vermutlich keinen Mehrwert.
	 */
	constructor(ageGroups: AgeGroupsServerData, competitor?: CampCompetitorData)
	{
		this._onSubmodelChanged = this._onSubmodelChanged.bind(this);
		this._onGeburtsjahrChanged = this._onGeburtsjahrChanged.bind(this);
		this._getIsValid = this._getIsValid.bind(this);

		Asserter.assert(competitor === undefined || competitor.id !== undefined, 'must be set');

		this._ageGroups = new Map(Object.entries(ageGroups));
		this._jahrgangValidator = new MultiAgeGroupValidator(this._ageGroups);

		const id = competitor?.id ?? CampCompetitorModel._nextCompetitorId();
		this._id = new ValidatedTextModel(id.toString(), _dummyValidator);
		this._vorname = new ValidatedTextModel(competitor?.vorname ?? '', _nameValidator);
		this._vorname.onChanged.subscribe(this._onSubmodelChanged);
		this._nachname = new ValidatedTextModel(competitor?.nachname ?? '', _nameValidator);
		this._nachname.onChanged.subscribe(this._onSubmodelChanged);
		this._geburtsjahr = new ValidatedTextModel(competitor?.geburtsjahr.toString() ?? '', this._jahrgangValidator);
		this._geburtsjahr.onChanged.subscribe(this._onGeburtsjahrChanged);

		this._createAk(competitor?.altersklasse);

		this._isValid = new ComputedFlagModel(this._getIsValid, this);
	}

	private _createAk(altersklasse: Optional<string>): void
	{
		let akChoices: Nullable<string>[] = [null];

		if (this._geburtsjahr.isValid())
		{
			Asserter.assert(altersklasse !== undefined, 'Must be set when year of birth is valid.');

			akChoices = this._jahrgangValidator.possibleAgeGroups();
		}

		this._altersklasse = ValidatedChoiceModel.create(akChoices, altersklasse ?? null, _akValidator);
		this._altersklasse.onChanged.subscribe(this._onSubmodelChanged);
	}

	get onChanged(): IEvent<this>                { return this._onChanged; }

	get id(): IValidatedTextModel                { return this._id; }
	get vorname(): IValidatedTextModel           { return this._vorname; }
	get nachname(): IValidatedTextModel          { return this._nachname; }
	get geburtsjahr(): IValidatedTextModel       { return this._geburtsjahr; }
	get altersklasse(): IAltersklasseChoiceModel { return this._altersklasse; }
	get isValid(): IFlagModel                    { return this._isValid; }

	clone(): CampCompetitorModel
	{
		// blöd, dass man das nicht automatisch machen kann
		const ageGroups: AgeGroupsServerData = {
			U18: this._ageGroups.get('U18')!,
			U21: this._ageGroups.get('U21')!
		};

		const copy = new CampCompetitorModel(ageGroups);

		copy.vorname.setText(this.vorname.text);
		copy.nachname.setText(this.nachname.text);
		copy.geburtsjahr.setText(this.geburtsjahr.text);
		copy.altersklasse.setSelected(this.altersklasse.selected);

		return copy;
	}

	toJSON(): CampCompetitorData
	{
		Asserter.assert(this.isValid.value, 'not allowed when invalid');

		const idAsInt = stringToInt(this._id.text);

		return {
			id: idAsInt > 0 ? idAsInt : undefined,
			vorname: this._vorname.text,
			nachname: this._nachname.text,
			geburtsjahr: stringToInt(this._geburtsjahr.text),
			altersklasse: this._altersklasse.selected!
		};
	}

	private _getIsValid(): boolean
	{
		return this._vorname.isValid()
			&& this._nachname.isValid()
			&& this._geburtsjahr.isValid()
			&& this._altersklasse.isValid();
	}

	private _onSubmodelChanged(): void
	{
		this._notifyChange();
	}

	private _notifyChange(): void
	{
		this._onChanged.notify(this, undefined);
	}

	private _onGeburtsjahrChanged(): void
	{
		if (this._geburtsjahr.isValid())
		{
			const akChoices = this._jahrgangValidator.possibleAgeGroups();
			const altersklasse = akChoices[0];

			this._altersklasse.setChoices(akChoices);
			this._altersklasse.setSelected(altersklasse);
		}
		else
			this._altersklasse.setChoices([null]);

		this._notifyChange();
	}

	private static _nextCompetitorId(): number
	{
		 return CampCompetitorModel._competitorId--;
	}

	private static _competitorId = -1;

	private readonly _ageGroups: Map<string, AgeGroupServerData>;
	private readonly _jahrgangValidator: MultiAgeGroupValidator;
	private readonly _id: ValidatedTextModel;
	private readonly _vorname: ValidatedTextModel;
	private readonly _nachname: ValidatedTextModel;
	private readonly _geburtsjahr: ValidatedTextModel;
	private _altersklasse: ValidatedChoiceModel<Nullable<string>> = null!;
	private readonly _isValid: ComputedFlagModel<this>;
	private readonly _onChanged = new Event<this>();
}


//==============================================================================


const _dummyValidator = new DummyValidator();
const _nameValidator = new NameValidator();
const _akValidator = new NotNullValidator<string>();


export { CampCompetitorModel };
