import { PureComponent, ReactNode } from 'react';
import Container from 'react-bootstrap/Container';
import { getValidChangedIndicatorPostfix } from '../common/utils';
import { ComputedChoiceModel } from '../models/ComputedChoiceModel';
import { ComputedFlagModel } from '../models/ComputedFlagModel';
import { FileUploadEntryModel } from '../models/FileUploadEntryModel';
import { IChoiceModel } from '../models/IChoiceModel';
import { IFlagModel } from '../models/IFlagModel';
import { ModelCollection } from '../models/ModelCollection';
import { DateTimeEditWithModel } from './DateTimeEdit';
import { Dropdown } from './Dropdown';
import { DropdownItem } from './DropdownItem';
import { FileSelectWithModel } from './FileSelect';
import { Hideable } from './Hideable';
import { Label } from './Label';
import { LineEditWithModel } from './LineEdit';
import { Row } from './Row';


class Computed
{
	constructor(entry: FileUploadEntryModel)
	{
		this._getIsSaveable = this._getIsSaveable.bind(this);
		this._getIndicator = this._getIndicator.bind(this);

		this._entry = entry;
		this._models = new ModelCollection([entry, entry.wasChanged]);
		this._isSaveable = new ComputedFlagModel(this._getIsSaveable, entry);
		this._indicator = new ComputedChoiceModel(this._getIndicator, ['-danger', '-success', ''], this._models);
	}

	get isSaveable(): IFlagModel          { return this._isSaveable; }
	get indicator(): IChoiceModel<string> { return this._indicator; }

	setEntry(entry: FileUploadEntryModel): void
	{
		this._entry = entry;
		this._models.setModels([entry, entry.wasChanged]);
		this._isSaveable.setModel(entry);
	}

	private _getIsSaveable(model: FileUploadEntryModel): boolean
	{
		return this._entry.isValid.value && this._entry.file.file !== undefined;
	}

	/**
	 * Der Indicator soll anzeigen, ob ein Entry valide ist oder geändert wurde.
	 */
	private _getIndicator(models: ModelCollection): string
	{
		return getValidChangedIndicatorPostfix(
			this._entry.isValid.value,
			this._entry.wasChanged.value
		);
	}

	private _entry: FileUploadEntryModel;
	private readonly _models: ModelCollection;
	private readonly _isSaveable: ComputedFlagModel<FileUploadEntryModel>;
	private readonly _indicator: ComputedChoiceModel<ModelCollection>;
}


//==============================================================================


type Props = {
	entry: FileUploadEntryModel;
	onDelete?: (entry: FileUploadEntryModel) => Promise<void>
};


/**
 * Formular für die Eingabe und das Updaten eines FileUploads.
 */
class FileUploadEntryForm extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onSaveClicked = this._onSaveClicked.bind(this);
		this._onDeleteClicked = this._onDeleteClicked.bind(this);
		this._onIndicatorChanged = this._onIndicatorChanged.bind(this);

		this._computed = new Computed(this.props.entry);
	}

	render(): ReactNode
	{
		return (
			<Container fluid="md">
				<div className={`callout${this._computed.indicator.selected} bg-white p-2 my-3 rounded`}>
					<Row className="align-items-center gy-1">
						<Label label="Id:" className="col-2" />
						<div className="col-4">
							<LineEditWithModel model={this.props.entry.id} isEnabled={false} />
						</div>

						<div className="col-5" />
						<div className="col-1 d-flex flex-row-reverse">
							<Dropdown iconSize="1.3em">
								{this._renderButton('Speichern', this._onSaveClicked, this._computed.isSaveable)}
								{this._renderButton('Löschen', this._onDeleteClicked)}
							</Dropdown>
						</div>

						<Label label="Name:" className="col-2" />
						<div className="col-4">
							<LineEditWithModel model={this.props.entry.name} />
						</div>
						<Label label="File:" className="col-2" />
						<div className="col-4">
							<FileSelectWithModel model={this.props.entry.file} />
						</div>
						<Hideable isShown={this.props.entry.isPersisted}>
							<Label label="Created:" className="col-2" />
							<div className="col-4">
								<DateTimeEditWithModel model={this.props.entry.created} isEnabled={false} />
							</div>
							<Label label="Updated:" className="col-2" />
							<div className="col-4">
								<DateTimeEditWithModel model={this.props.entry.updated} isEnabled={false} />
							</div>
							<Label label="URL:" className="col-2" />
							<div className="col-10">
								<LineEditWithModel model={this.props.entry.url} isEnabled={false} />
							</div>
						</Hideable>
					</Row>
				</div>
			</Container>
		);
	}

	componentDidMount(): void
	{
		this._computed.indicator.onChanged.subscribe(this._onIndicatorChanged);
	}

	componentWillUnmount(): void
	{
		this._computed.indicator.onChanged.unsubscribe(this._onIndicatorChanged);
	}

	componentDidUpdate(prevProps: Props): void
	{
		this._computed.setEntry(this.props.entry);
	}

	private _renderButton(label: string, handler: () => void, isEnabled?: IFlagModel): ReactNode
	{
		return <DropdownItem label={label} onClicked={handler} isEnabled={isEnabled} />;
	}

	private _onSaveClicked(): Promise<void>
	{
		return this.props.entry.save();
	}

	private _onDeleteClicked(): Promise<void>
	{
		return this.props.onDelete?.call(undefined, this.props.entry) ?? Promise.resolve();
	}

	private _onIndicatorChanged(indicator: IChoiceModel<string>): void
	{
		this.forceUpdate();
	}

	private readonly _computed: Computed;
}


export { FileUploadEntryForm };
