import { Asserter } from '../common/Asserter';
import { EventAggregator } from './EventAggregator';
import { EventHandler, IEvent } from './IEvent';


class Event<TSubject, TEventArg = undefined> implements IEvent<TSubject, TEventArg>
{
	subscribe(handler: EventHandler<TSubject, TEventArg>)
	{
		Asserter.assert(this._observers.indexOf(handler) === -1, 'handler is already subscribed');
		this._observers.push(handler);
	}

	unsubscribe(handler: EventHandler<TSubject, TEventArg>)
	{
		const idx = this._observers.indexOf(handler);
		Asserter.assert(idx !== -1, 'handler is not subscribed');
		this._observers.splice(idx, 1);
	}

	notify(subject: TSubject, arg: TEventArg)
	{
		// Wir machen hier explizit eine Kopie der Observers.
		// Das ist wichtig, weil ein Handler die Observers auch modifizieren kann.
		const observers = [...this._observers];
		observers.forEach(handler => handler(subject, arg));
	}

	/**
	 * Aggregiert alle Notifikationen, die während action() getriggert werden.
	 * Die Notifikationen werden zuzätzlich noch eingedampft: doppelte Notifikationen
	 * werden entfernt -- aber nur, wenn sie direkt nacheinander kommen.
	 * Nach Ende von action(), werden die eingedampften Notifikationen dann
	 * wiedergegeben.
	 * Idealerweise führt das zu einer Reduzierung von Notifikationen.
	 */
	collectWhile(action: () => void)
	{
		const savedObservers = Array.from(this._observers);
		savedObservers.forEach(o => this.unsubscribe(o));

		const aggregator = new EventAggregator<TSubject, TEventArg>();
		this.subscribe(aggregator.record); // ist bereits gebunden

		try
		{
			action();
		}
		finally
		{
			this.unsubscribe(aggregator.record);
			savedObservers.forEach(o => this.subscribe(o));
		}

		aggregator.replay(this);
	}

	private _observers: EventHandler<TSubject, TEventArg>[] = [];
}


export { Event };
