import { PureComponent, ReactNode } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { getValidChangedIndicatorPostfix } from '../common/utils';
import { ComputedChoiceModel } from '../models/ComputedChoiceModel';
import { ComputedFlagModel } from '../models/ComputedFlagModel';
import { FlagModel } from '../models/FlagModel';
import { IChoiceModel } from '../models/IChoiceModel';
import { IFlagModel } from '../models/IFlagModel';
import { ModelCollection } from '../models/ModelCollection';
import { NewstickerEntryModel } from '../models/NewstickerEntryModel';
import { CheckboxWithModel } from './Checkbox';
import { DateTimeEditWithModel } from './DateTimeEdit';
import { Dropdown } from './Dropdown';
import { DropdownItem } from './DropdownItem';
import { HideableWithModel } from './Hideable';
import { Label } from './Label';
import { LineEditWithModel } from './LineEdit';
import { NewstickerEntry } from './NewstickerEntry';
import { Row } from './Row';
import { ValidatedLineEditWithModel } from './ValidatedLineEdit';


/**
 * Ähnlich wie bei RegistrationForm, jedoch etwas anders:
 * Im Gegensatz zum RegistrationModel reflektiert das NewstickerEntryModel selbst nicht
 * die Änderungen an seinem wasChanged-Flag. Das macht das Handling im NewstickerEntryModel
 * leichter. Jedoch führt das dazu, dass wir hier neben der Abhängigkeit zum NewstickerEntryModel
 * auch die Abhängigkeit zu dessen wasChanged-Flags haben. Daher die Verwendung
 * der ModelCollection. -- Bin mir noch nicht sicher, was besser ist.
 *
 * Ein anderes Problem ist, dass die computed-Eigenschaften viel zu oft ausgewertet werden.
 * Wenn man z.B. das zugrunde liegende Model refresht, wird potentiell jedes einzelene Submodel
 * geändert, was zu je einer onChanged-Notification führt und damit zur Neuberechnung der
 * computed-Eigenschaften.
 * => Man müsste irgendwie die Reihe von onChanged-Notifikationen, die während einer *Transaktion*
 * getriggert werden, zu einer Notifikation zusammenfassen.
 */
class Computed
{
	constructor(entry: NewstickerEntryModel)
	{
		this._getIndicator = this._getIndicator.bind(this);

		this._entry = entry;
		this._models = new ModelCollection([entry, entry.wasChanged]);
		this._isSaveable = new ComputedFlagModel(m => m.isValid.value === true && m.wasChanged.value === true, entry);
		this._isRefreshable = new ComputedFlagModel(m => m.isPersisted === true, entry);
		this._indicator = new ComputedChoiceModel(this._getIndicator, ['-danger', '-success', ''], this._models);
	}

	get isSaveable(): IFlagModel          { return this._isSaveable; }
	get isRefreshable(): IFlagModel       { return this._isRefreshable; }
	get indicator(): IChoiceModel<string> { return this._indicator; }

	setEntry(entry: NewstickerEntryModel): void
	{
		[
			this._isSaveable,
			this._isRefreshable
		].forEach(m => m.setModel(entry));

		this._entry = entry;
		this._models.setModels([entry, entry.wasChanged]);
	}

	/**
	 * Der Indicator soll anzeigen, ob ein Entry valide ist oder geändert wurde.
	 */
	private _getIndicator(models: ModelCollection): string
	{
		return getValidChangedIndicatorPostfix(
			this._entry.isValid.value,
			this._entry.wasChanged.value
		);
	}

	private _entry: NewstickerEntryModel;
	private readonly _models: ModelCollection;
	private readonly _isSaveable: ComputedFlagModel<NewstickerEntryModel>;
	private readonly _isRefreshable: ComputedFlagModel<NewstickerEntryModel>;
	private readonly _indicator: ComputedChoiceModel<ModelCollection>;
}


//==============================================================================


type Props = {
	entry: NewstickerEntryModel;
	onDelete?: (entry: NewstickerEntryModel) => Promise<void>
};


/**
 * Formular für die Eingabe eines NewstickerEntry.
 */
class NewstickerEntryForm extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onSaveClicked = this._onSaveClicked.bind(this);
		this._onRefreshClicked = this._onRefreshClicked.bind(this);
		this._onIndicatorChanged = this._onIndicatorChanged.bind(this);
		this._onDeleteClicked = this._onDeleteClicked.bind(this);

		this._computed = new Computed(this.props.entry);
	}

	render(): ReactNode
	{
		return (
			<Container fluid="md">
				<div className={`callout${this._computed.indicator.selected} bg-white p-2 my-3 rounded`}>
					<Row className="align-items-center gy-1">
						<Label label="Id:" className="col-2" />
						<div className="col-4">
							<LineEditWithModel model={this.props.entry.id} isEnabled={false} />
						</div>
						<div className="col-5" />
						<div className="col-1 d-flex flex-row-reverse">
							<Dropdown iconSize="1.3em">
								{this._renderButton('Speichern', this._onSaveClicked, this._computed.isSaveable)}
								{this._renderButton('Refresh', this._onRefreshClicked, this._computed.isRefreshable)}
								{this._renderButton('Löschen', this._onDeleteClicked)}
							</Dropdown>
						</div>
						<Label label="Date, Time:" className="col-2" />
						<div className="col-4">
							<DateTimeEditWithModel model={this.props.entry.dateTime} />
						</div>
						<div className="col-6" />
						<Label label="Message:" className="col-2" />
						<div className="col-10">
							<Form.Control as={ValidatedLineEditWithModel} type="textarea" model={this.props.entry.text} rows={3} />
						</div>
						<Label label="Show Preview:" className="col-2" />
						<div className="col-10">
							<CheckboxWithModel label="" type="switch" isChecked={this._previewShown} />
						</div>
					</Row>
					<HideableWithModel isShown={this._previewShown}>
						<NewstickerEntry model={this.props.entry} />
					</HideableWithModel>
				</div>
			</Container>
		);
	}

	componentDidMount(): void
	{
		this._computed.indicator.onChanged.subscribe(this._onIndicatorChanged);
	}

	componentWillUnmount(): void
	{
		this._computed.indicator.onChanged.unsubscribe(this._onIndicatorChanged);
	}

	componentDidUpdate(prevProps: Props): void
	{
		this._computed.setEntry(this.props.entry);
	}

	private _renderButton(label: string, handler: () => void, isEnabled?: IFlagModel): ReactNode
	{
		return <DropdownItem label={label} onClicked={handler} isEnabled={isEnabled} />;
	}

	private _onSaveClicked(): Promise<void>
	{
		return this.props.entry.save();
	}

	private _onRefreshClicked(): Promise<void>
	{
		return this.props.entry.refresh();
	}

	private _onDeleteClicked(): Promise<void>
	{
		return this.props.onDelete?.call(undefined, this.props.entry) ?? Promise.resolve();
	}

	private _onIndicatorChanged(indicator: IChoiceModel<string>): void
	{
		this.forceUpdate();
	}

	private readonly _computed: Computed;
	private readonly _previewShown = new FlagModel(false);
}


export { NewstickerEntryForm };
