import { Asserter } from '../common/Asserter';
import { ChoiceModel } from './ChoiceModel';
import { EventForwarder } from './EventForwarder';
import { IChoiceModel } from './IChoiceModel';
import { IEvent, moveSubscription } from './IEvent';
import { IModel } from './IModel';


/**
 * Funktion, die zum Model die Selektion liefert.
 */
type Compute<TModel> = (model: TModel) => string;


class ComputedChoiceModel<TModel extends IModel> implements IChoiceModel<string>
{
	constructor(compute: Compute<TModel>, choices: Readonly<string[]>, sourceModel: TModel)
	{
		this._onModelChanged = this._onModelChanged.bind(this);

		this._compute = compute;
		this._sourceModel = sourceModel;
		this._choiceModel = new ChoiceModel(choices, compute(sourceModel));
		this._onChanged = new EventForwarder(this._choiceModel.onChanged, this);

		this._sourceModel.onChanged.subscribe(this._onModelChanged);
	}

	get onChanged(): IEvent<this>
	{
		return this._onChanged.event;
	}

	get choices()
	{
		return this._choiceModel.choices;
	}

	get selected()
	{
		return this._choiceModel.selected;
	}

	setModel(model: TModel)
	{
		if (model === this._sourceModel)
			return;

		moveSubscription(this._sourceModel.onChanged, model.onChanged, this._onModelChanged);
		this._sourceModel = model;
		this._onModelChanged(this._sourceModel);
	}

	setChoices(choices: Readonly<string[]>)
	{
		Asserter.fail('readonly')
	}

	setSelected(item: string)
	{
		Asserter.fail('readonly')
	}

	private _onModelChanged(model: IModel)
	{
		this._choiceModel.setSelected(this._compute(this._sourceModel));
	}

	private _compute: Compute<TModel>;
	private _sourceModel: TModel;
	private _choiceModel: ChoiceModel<string>;
	private _onChanged: EventForwarder<ChoiceModel<string>, this>;
}


export { ComputedChoiceModel };
