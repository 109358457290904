import { IValidator } from '../common/IValidator';
import { RegistrationIdGenerator } from '../common/RegistrationIdGenerator';
import { DateValidator } from '../common/Validators';
import { ITextModel } from './ITextModel';


class DateValidatorWhenPersisted implements IValidator<string>
{
	constructor(id: ITextModel)
	{
		this._id = id;
	}

	isValid(userInput: string): boolean
	{
		// Wenn die Meldung bereits persistiert ist, muss ein valides Datum angegeben werden.
		if (RegistrationIdGenerator.isPermanentId(this._id.text))
			return this._dateValidator.isValid(userInput);

		// Ansonsten darf das Datum auch leer sein.
		return userInput.length === 0 || this._dateValidator.isValid(userInput);
	}

	private _id: ITextModel;
	private _dateValidator = new DateValidator();
}


export { DateValidatorWhenPersisted };
