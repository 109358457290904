import { createRef, PureComponent, ReactNode, RefObject } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { TR, Translator } from '../common/Translator';
import { ComputedFlagModel } from '../models/ComputedFlagModel';
import { ICompetitorModel } from '../models/ICompetitorModel';
import { moveSubscription } from '../models/IEvent';
import { IObservableCollection } from '../models/IObservableCollection';
import { IRegistrationModel } from '../models/IRegistrationModel';
import { Button } from './Button';
import { CompetitorEdit } from './CompetitorEdit';
import { Label } from './Label';
import { RegistrationFormType } from './RegistrationForm';
import { Row } from './Row';
import { WithTooltip } from './WithTooltip';


class CompetitorHeader extends PureComponent
{
	constructor(props: {})
	{
		super(props);

		this._onLanguageChanged = this._onLanguageChanged.bind(this);
	}

	render()
	{
		// Hint: '' ist für die Spalte, wo der Entfernen-Button hinkommt; die bleibt unbeschriftet.
		const labels = ['Vorname', 'Nachname', 'Geburtsjahr', 'U18/U21', 'Gewicht', 'Staatsbürgerschaft', ''].map((l, idx) => {
			let labelText = '';
			if (l !== '')
				labelText = TR(`RegistrationForm::${l}`);

			if (l !== 'Staatsbürgerschaft')
				return <Label key={idx} label={labelText} className='d-none d-lg-block col-lg' />;

			const label = <Label>{labelText} <FaInfoCircle /></Label>;
			return (
				<div key={idx} className="d-none d-lg-block col-lg">
					<WithTooltip content={label} tooltip={TR(`RegistrationForm::Hinweis Staatsbürgerschaft`)} />
				</div>
			);
		});
		return (
			<Row className="mb-1">
				{labels}
			</Row>
		);
	}

	componentDidMount(): void
	{
		Translator.instance().languageChanged.subscribe(this._onLanguageChanged);
	}

	componentWillUnmount(): void
	{
		Translator.instance().languageChanged.unsubscribe(this._onLanguageChanged);
	}

	private _onLanguageChanged(): void
	{
		this.forceUpdate();
	}
}


class Computed
{
	constructor(registration: IRegistrationModel)
	{
		this._isDeleteVisible = new ComputedFlagModel(competitors => competitors.items.length > 1, registration.competitors);
	}

	get isDeleteVisible()
	{
		return this._isDeleteVisible;
	}

	setRegistration(registration: IRegistrationModel)
	{
		this._isDeleteVisible.setModel(registration.competitors);
	}

	private _isDeleteVisible: ComputedFlagModel<IObservableCollection<ICompetitorModel>>;
}


type Props = {
	registration: IRegistrationModel;
	formType: RegistrationFormType;
};


/**
 * Der Teil des Meldeformulars, wo die Kämpfer eingegeben werden.
 */
class CompetitorForm extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onCompetitorAdded = this._onCompetitorAdded.bind(this);
		this._onCompetitorRemoved = this._onCompetitorRemoved.bind(this);
		this._onCompetitorsChanged = this._onCompetitorsChanged.bind(this);
		this._onLanguageChanged = this._onLanguageChanged.bind(this);

		this._focusTarget = createRef<CompetitorEdit>();
		this._computed = new Computed(props.registration);
	}

	render(): ReactNode
	{
		const lastIdx = this.props.registration.competitors.items.length - 1;
		const competitors = this.props.registration.competitors.items.map((c, idx) => {
			// Die Ref nur an das letzte Element übergeben.
			const ref = idx === lastIdx ? this._focusTarget : undefined;
			return (
				<Row key={c.id.text} className="gy-1 mb-1 align-items-center">
					<CompetitorEdit ref={ref} competitor={c} withDelete={this._computed.isDeleteVisible} onDeleted={this._onCompetitorRemoved} />
				</Row>
			);
		});

		let hint: ReactNode = null;
		if (this.props.formType !== 'Admin')
			hint = (
				<div className="callout-warning rounded bg-light p-2 mb-3">
					{TR('RegistrationForm::Hinweis zur Meldung')}
				</div>
			);

		return (
			<div className='card border-secondary my-3'>
				<div className='card-header'>{TR('RegistrationForm::Angaben zu den Wettkämpfern')}</div>
				<div className='card-body'>
					{hint}

					<CompetitorHeader />
					{competitors}
					<Button className='btn btn-outline-secondary' onClicked={this._onCompetitorAdded}>
						{TR('RegistrationForm::Hinzufügen')}
					</Button>
				</div>
			</div>
		);
	}

	componentDidMount()
	{
		this.props.registration.competitors.onChanged.subscribe(this._onCompetitorsChanged);
		Translator.instance().languageChanged.subscribe(this._onLanguageChanged);
	}

	componentWillUnmount()
	{
		this.props.registration.competitors.onChanged.unsubscribe(this._onCompetitorsChanged);
		Translator.instance().languageChanged.unsubscribe(this._onLanguageChanged);
	}

	componentDidUpdate(prevProps: Props)
	{
		this._computed.setRegistration(this.props.registration);
		moveSubscription(prevProps.registration.competitors.onChanged, this.props.registration.competitors.onChanged, this._onCompetitorsChanged);

		if (this._focusLastOnNextUpdate)
		{
			this._focusTarget.current!.focus();
			this._focusLastOnNextUpdate = false;
		}
	}

	private _onCompetitorAdded()
	{
		this.props.registration.addCompetitor();
		this._focusLastOnNextUpdate = true;
	}

	private _onCompetitorRemoved(competitor: ICompetitorModel)
	{
		this.props.registration.removeCompetitor(competitor);
		this._focusLastOnNextUpdate = true;
	}

	private _onCompetitorsChanged(competitors: IObservableCollection<ICompetitorModel>)
	{
		this.forceUpdate();
	}

	private _onLanguageChanged(translator: Translator)
	{
		this.forceUpdate();
	}

	private _focusTarget: RefObject<CompetitorEdit>;
	private _focusLastOnNextUpdate: boolean = false;
	private _computed: Computed;
}


export { CompetitorForm };
