import { ChangeEvent, PureComponent, ReactNode } from 'react';
import { Asserter } from '../common/Asserter';
import { moveSubscription } from '../models/IEvent';
import { IFlagModel } from '../models/IFlagModel';
import { IValidatedChoiceModel } from '../models/IValidatedChoiceModel';


type Props = {
	values: Readonly<string[]>;
	selected: string;
	isValid: boolean;
	isEnabled?: boolean;
	onChanged: (value: string) => void;
};


class ValidatedCombobox extends PureComponent<Props>
{
	constructor(props: Props)
	{
		super(props);

		this._onChanged = this._onChanged.bind(this);
	}

	render(): ReactNode
	{
		const selectedIndex = this.props.values.indexOf(this.props.selected);
		Asserter.assert(selectedIndex !== -1, 'invalid selected entry');

		const options = this.props.values.map(e => <option key={e} value={e}>{e}</option>);

		let classNames = ['form-select'];
		if (this.props.isValid)
			classNames.unshift('is-valid');
		else
			classNames.unshift('is-invalid');

		const isDisabled = (this.props.isEnabled === false || options.length <= 1) ? true : undefined;
		return (
			<select
				className={classNames.join(' ')}
				value={this.props.selected}
				onChange={this._onChanged}
				disabled={isDisabled}
			>
				{options}
			</select>
		);
	}

	private _onChanged(e: ChangeEvent<HTMLSelectElement>): void
	{
		this.props.onChanged(e.target.value);
	}
}


//==============================================================================


type PropsWithModel = {
	model: IValidatedChoiceModel<string>;
	isEnabled?: boolean;
};


class ValidatedComboboxWithModel extends PureComponent<PropsWithModel>
{
	constructor(props: PropsWithModel)
	{
		super(props);

		this._onUiChanged = this._onUiChanged.bind(this);
		this._onModelChanged = this._onModelChanged.bind(this);
	}

	render(): ReactNode
	{
		return (
			<ValidatedCombobox
				values={this.props.model.choices}
				selected={this.props.model.selected}
				isValid={this.props.model.isValid()}
				onChanged={this._onUiChanged}
				isEnabled={this.props.isEnabled}
			/>
		);
	}

	componentDidMount(): void
	{
		this.props.model.onChanged.subscribe(this._onModelChanged);
	}

	componentWillUnmount(): void
	{
		this.props.model.onChanged.unsubscribe(this._onModelChanged);
	}

	componentDidUpdate(prevProps: PropsWithModel): void
	{
		moveSubscription(prevProps.model.onChanged, this.props.model.onChanged, this._onModelChanged);
	}

	private _onModelChanged(model: IValidatedChoiceModel<string>): void
	{
		this.forceUpdate();
	}

	private _onUiChanged(text: string): void
	{
		this.props.model.setSelected(text);
	}
}


//==============================================================================


type PropsWithFlagModel = {
	model: IValidatedChoiceModel<string>;
	isEnabled?: IFlagModel;
};


class ValidatedComboboxWithFlagModel extends PureComponent<PropsWithFlagModel>
{
	constructor(props: PropsWithFlagModel)
	{
		super(props);
		this._onFlagModelChanged = this._onFlagModelChanged.bind(this);
	}

	render(): ReactNode
	{
		return <ValidatedComboboxWithModel model={this.props.model} isEnabled={this.props.isEnabled?.value} />;
	}

	componentDidMount(): void
	{
		this.props.isEnabled?.onChanged.subscribe(this._onFlagModelChanged);
	}

	componentWillUnmount(): void
	{
		this.props.isEnabled?.onChanged.unsubscribe(this._onFlagModelChanged);
	}

	componentDidUpdate(prevProps: PropsWithFlagModel): void
	{
		moveSubscription(prevProps.isEnabled?.onChanged, this.props.isEnabled?.onChanged, this._onFlagModelChanged);
	}

	private _onFlagModelChanged(flagModel: IFlagModel): void
	{
		this.forceUpdate();
	}
}


export { ValidatedCombobox, ValidatedComboboxWithFlagModel, ValidatedComboboxWithModel };
