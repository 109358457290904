import { PureComponent, ReactNode } from 'react';
import { Asserter } from '../common/Asserter';
import { gkString, renderElementList } from '../common/utils';
import { ICompetitorModel } from '../models/ICompetitorModel';
import { IRegistrationModel } from '../models/IRegistrationModel';
import { Callout } from './Callout';


type Props = {
	registration: IRegistrationModel;
	info?: string; // string ist vorerst ausreichend. Könnte auch ElementOrRenderProp sein.
};


/**
 * Zeigt die Meldung an -- aber nur als Snapshot.
 * Der View reagiert nicht auf Änderungen an der Meldung.
 *
 * Das info-Prop kann dazu verwendet werden, zusätzliche Information anzuzeigen.
 * Das wird dazu verwendet, wenn wir z.B. nach dem Abschicken der Meldung
 * oder nach der Bestätigung der Meldung auf die Details-Seite umleiten.
 * Je nachdem von wo wir umleiten, wird unterschiedlicher Text angezeigt.
 * Ist aber optional und nicht gesetzt, wenn wir einfach nur die Meldung anzeigen wollen.
 */
class RegistrationView extends PureComponent<Props>
{
	render(): ReactNode
	{
		Asserter.assert(this.props.registration.isValid.value === true, 'Registration must be valid');

		const reg = this.props.registration;
		let verband = '';
		if (reg.verband.isValid())
			verband = `, ${reg.verband.selected!.name}`;

		const headline = `${reg.verein.text}${verband}, ${reg.nation.selected!.name}`;

		const rows = renderElementList(reg.competitors.items.map(_competitorRow));

		return (
			<>
				<Callout className="rounded bg-white p-2 my-3" variant="-success">
					{this.props.info}
				</Callout>
				<Callout className="rounded bg-white p-2 my-3" variant="-success">
					<h3>{reg.id.text}</h3>
					<h6>{headline}</h6>
					{rows}
				</Callout>
			</>
		);
	}
}


//==============================================================================


function _competitorRow(competitor: ICompetitorModel): ReactNode
{
	const gk = gkString(competitor.gewichtsklasse.selected!);

	const line = `${competitor.vorname.text} ${competitor.nachname.text}, ${competitor.geburtsjahr.text}, ${competitor.altersklasse.selected}, ${gk}, ${competitor.nation.selected!.name}`;
	return (
		<>
			{line}
			<br />
		</>
	);
}


export { RegistrationView };
