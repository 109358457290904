import { PureComponent } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';


class InvitationCamp extends PureComponent
{
	render()
	{
		return (
			<Container fluid="lg" className="my-4">

				<Card border="secondary" className="my-3">
					<Card.Header as="h5">
					International Training Camp &mdash; Masters Bremen 2025
					</Card.Header>
					<Card.Body>

						<table className="table">
							<tbody>

								<tr>
									<th scope="row">
										Venue
									</th>
									<td>
										Congress Centre Bremen, Exhibition Hall 7 (Messehalle 7), Hollerallee/Findorffstr. (behind Bremen Central Station)
									</td>
								</tr>

								<tr>
									<th scope="row">
										Date
									</th>
									<td>
										24-26 March, 2025 (Mon - Wed)<br/>
										special extended period until 28 March, 2025 (Fri)
									</td>
								</tr>

								<tr>
									<th scope="row">
									Schedule
									</th>
									<td>
										<table className="table">
											<tbody>
												<tr>
													<td rowSpan={2}>Mon, 24 March, 2025</td><td>09:00 - 11:30</td>
													{/* <td className="text-center">
														15' Individual warm up including Ne-Waza<br/>
														4x4' Ne-Waza Randori<br/>
														10' Uchi-Komi<br/>
														6x4' Tachi-Waza Randori
													</td> */}
												</tr>
												<tr>
													<td>16:00 - 18:30</td>
													{/* <td className="text-center">
														10' Individual warm up<br/>
														10' Uchi-Komi<br/>
														8x4' Tachi-Waza Randori
													</td> */}
												</tr>
												<tr>
													<td rowSpan={2}>Tue, 25 March, 2025</td><td>09:00 - 11:30</td>
													{/* <td className="text-center">
														15' Individual warm up including Ne-Waza<br/>
														15' Ippon change Ne-Waza Randori<br/>
														10' Uchi-Komi<br/>
														6x4' Tachi-Waza Randori
													</td> */}
												</tr>
												<tr>
													<td>16:00 - 18:30</td>
													{/* <td className="text-center">
														10' Individual warm up<br/>
														10' Uchi-Komi<br/>
														7x4'+1' (GS) Tachi-Waza Randori
													</td> */}
												</tr>
												<tr>
													<td rowSpan={2}>Wed, 26 March, 2025</td><td>09:00 - 11:30</td>
													{/* <td className="text-center">
														10' Individual warm up<br/>
														10' Uchi-Komi<br/>
														Team-Fights, for the rest normal Randori
													</td> */}
												</tr>
												<tr>
													<td>16:00 - 18:30</td>
												</tr>
												<tr>
													<td rowSpan={2}>Thu, 27 March, 2025 (special extended period)</td><td>09:00 - 11:30</td>
												</tr>
												<tr>
													<td>16:00 - 18:30</td>
												</tr>
												<tr>
													<td>Fri, 28 March, 2025 (special extended period)</td><td>09:00 - 11:30</td>
												</tr>
											</tbody>
										</table>
									</td>
								</tr>

								<tr>
									<th scope="row">
										Registration fee
									</th>
									<td>
										The registration fee will be 60 EUR per Judoka for Monday - Wednesday.<br/>
										The extended period until Friday noon will be 20 EUR per Judoka.
									</td>
								</tr>

								<tr>
									<th scope="row">
										Board
									</th>
									<td>
										You can order Lunch and Dinner directly after the training for 12 EUR per person (soup, full course, fruit, and drink).
									</td>
								</tr>

								{/* <tr>
									<th scope="row">
										Total Costs
									</th>
									<td>
										Total Costs start at EUR 250 per Judoka in 4 or 6 bed rooms.
										This price includes accommodation and breakfast for 5 nights and full board from dinner on Sunday (October 24) until lunch on Wednesday (October 27).
										Other hotel accommodation can also be organised depending on no.of persons and the period of staying in Bremen.
									</td>
								</tr> */}

								<tr>
									<th scope="row">
										Contact address
									</th>
									<td>
										Norbert Specker<br />
										Parkallee 89<br />
										28209 Bremen<br />
										Germany<br />
										phone: +49-160-97515414<br />
										e-mail: <a href="mailto:norbert.specker@specker-bremen.de">norbert.specker@specker-bremen.de</a>
									</td>
								</tr>

							</tbody>
						</table>

					</Card.Body>
				</Card>

			</Container>
		);
	}
}


export { InvitationCamp };
