import { normalizeCase } from '../common/utils';
import { Event } from './Event';
import { IEvent } from './IEvent';
import { ITextModel } from './ITextModel';


class TextModel implements ITextModel
{
	constructor(text: string = '')
	{
		this._text = text;
	}

	get onChanged(): IEvent<this>
	{
		return this._onChanged;
	}

	get text()
	{
		return this._text;
	}

	setText(text: string)
	{
		if (text === this._text)
			return;

		this._text = text;
		this._onChanged.notify(this, undefined);
	}

	static normalizeCase(textModel: ITextModel): void
	{
		textModel.setText(normalizeCase(textModel.text));
	}

	static swapValues(leftModel: ITextModel, rightModel: ITextModel): void
	{
		const leftValue = leftModel.text;
		leftModel.setText(rightModel.text);
		rightModel.setText(leftValue);
	}

	private _text: string;
	private _onChanged = new Event<this>();
}


export { TextModel };
